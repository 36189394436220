import React from 'react';

const AboutSection = ({ title, children }) => {
	return (
		<div className="mb-8">
			<h2 className="text-xl font-bold mb-4">{title}</h2>
			<p className="text-gray-700">{children}</p>
		</div>
	);
};

export default AboutSection; 