import React from 'react';
import { useIntl } from 'react-intl';

const TagFilter = ({ availableTags, selectedTags, handleTagSelect }) => {
	const intl = useIntl();
	const locale = intl.locale;

	// タグをソートする関数
	const sortTags = (tags) => {
		return tags.sort((a, b) => {
			const labelA = getTagLabel(a);
			const labelB = getTagLabel(b);
			if (locale === 'ja') {
				return labelA.localeCompare(labelB, 'ja', { sensitivity: 'base' });
			} else {
				return labelA.localeCompare(labelB, 'en');
			}
		});
	};

	const getTagLabel = (tag) => {
		return intl.formatMessage({ id: `tag.${tag.toLowerCase()}` });
	};

	const sortedTags = sortTags(availableTags);

	return (
		<div className="mb-4">
			<div className="flex flex-wrap gap-2">
				{sortedTags.map(tag => (
					<button
						key={tag}
						onClick={() => handleTagSelect(tag)}
						className={`px-3 py-1 rounded-full text-xs md:text-sm ${selectedTags.includes(tag)
							? 'bg-gray-900 text-white'
							: 'bg-gray-200 text-gray-700'
							}`}
					>
						{getTagLabel(tag)}
					</button>
				))}
			</div>
		</div>
	);
};

export default TagFilter;