import React from 'react';
import { books } from '../../data';
import { useIntl } from 'react-intl';

const Logo = () => {
	const intl = useIntl();
	return (
		<div>
			<h1 className="text-base md:text-xl font-bold">
				<a href="/">
					Design Book Collection
				</a>
			</h1>
			<p className="text-gray-500 text-xs md:text-sm">
				{books.length}{intl.formatMessage({ id: 'books.count' })}
			</p>
		</div>
	);
};

export default Logo;