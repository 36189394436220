import { useIntl } from 'react-intl';

const NotFoundBook = ({ clearFilters }) => {
	const intl = useIntl();

	return (
		<div className="text-center py-8">
			<p className="text-gray-600 mb-4">
				{intl.formatMessage({ id: 'search.noResults' })}<br />
				{intl.formatMessage({ id: 'search.tryOther' })}
			</p>
			<button
				onClick={clearFilters}
				className="bg-gray-900 text-white px-4 py-2 rounded-md transition-colors"
			>
				{intl.formatMessage({ id: 'button.clearFilters' })}
			</button>
		</div>
	);
};

export default NotFoundBook;
