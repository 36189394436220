import React from 'react';
import { FaList, FaTh } from 'react-icons/fa';
import { useIntl } from 'react-intl';

const ViewToggle = ({ isGridView, setIsGridView }) => {
	const intl = useIntl();
	return (
		<button
			onClick={() => setIsGridView(!isGridView)}
			className="text-sm flex items-center"
		>
			{isGridView ? (
				<>
					<FaList className="mr-1" />
					{intl.formatMessage({ id: 'view.list' })}
				</>
			) : (
				<>
					<FaTh className="mr-1" />
					{intl.formatMessage({ id: 'view.grid' })}
				</>
			)}
		</button>
	);
};

export default ViewToggle;