import React from 'react';
import { IoClose } from "react-icons/io5"; // react-iconsをインポート
import { useIntl } from 'react-intl';

const SearchBar = ({ searchTerm, handleSearchChange }) => {
	const intl = useIntl();
	const handleClear = () => {
		// 空文字列で検索イベントを発火
		handleSearchChange({ target: { value: '' } });
	};
	return (
		<div className="relative w-full md:w-96 mb-4 md:mb-0">
			<input
				type="text"
				placeholder={intl.formatMessage({ id: 'search.placeholder' })}
				value={searchTerm}
				onChange={handleSearchChange}
				className="p-2 border rounded w-full md:w-96"
			/>
			{searchTerm && (
				<button
					onClick={handleClear}
					className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
					aria-label={intl.formatMessage({ id: 'button.clearFilters' })}
				>
					<IoClose size={20} />
				</button>
			)}
		</div>
	);
};

export default SearchBar;