import React from 'react';

const Footer = () => {
	return (
		<footer className="bg-gray-800 text-white p-4 text-center text-sm">
			<p>© 2024 Design Book Collection</p>
		</footer>
	);
};

export default Footer;
