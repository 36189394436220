import React, { useRef, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { LuArrowUpDown } from "react-icons/lu";

const SortDropdown = ({ isDropdownOpen, toggleDropdown, sortOption, handleSortOptionChange }) => {
	const intl = useIntl();
	const dropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
				toggleDropdown(false);
			}
		};

		if (isDropdownOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isDropdownOpen, toggleDropdown]);

	return (
		<div className="text-sm relative" ref={dropdownRef}>
			<button
				onClick={() => toggleDropdown(!isDropdownOpen)}
				className="flex items-center"
			>
				<span className="mr-1">
					<LuArrowUpDown />
				</span>
				<span>{intl.formatMessage({ id: sortOption === intl.formatMessage({ id: 'sort.newest' }) ? 'sort.newest' : 'sort.oldest' })}</span>
			</button>

			{isDropdownOpen && (
				<div className="absolute right-0 mt-1 w-48 bg-white border rounded shadow-lg">
					<button
						onClick={() => handleSortOptionChange(intl.formatMessage({ id: 'sort.newest' }))}
						className="block w-full text-left px-4 py-2 hover:bg-gray-100"
					>
						{intl.formatMessage({ id: 'sort.newest' })}
					</button>
					<button
						onClick={() => handleSortOptionChange(intl.formatMessage({ id: 'sort.oldest' }))}
						className="block w-full text-left px-4 py-2 hover:bg-gray-100"
					>
						{intl.formatMessage({ id: 'sort.oldest' })}
					</button>
				</div>
			)}
		</div>
	);
};

export default SortDropdown;