import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import jaMessages from './i18n/messages/ja';
import enMessages from './i18n/messages/en';
import About from './components/About';

const messages = {
	ja: jaMessages,
	en: enMessages
};

function App() {
	const [locale, setLocale] = useState('ja');

	// URLのパスから言語を設定
	useEffect(() => {
		const path = window.location.pathname;
		if (path.startsWith('/en')) {
			setLocale('en');
		} else {
			setLocale('ja');
		}
	}, []);

	const handleLanguageChange = (newLocale) => {
		setLocale(newLocale);
		// 言語変更時にURLを変更
		const newPath = newLocale === 'en' ? '/en' : '/';
		window.history.pushState({}, '', newPath);
	};

	return (
		<Router>
			<IntlProvider messages={messages[locale]} locale={locale}>
				<div className="min-h-screen flex flex-col">
					<Header onLanguageChange={handleLanguageChange} currentLocale={locale} />
					<Routes>
						<Route path="/" element={<Main />} />
						<Route path="/en" element={<Main />} />
						<Route path="/about" element={<About />} />
						<Route path="/en/about" element={<About />} />
						<Route path="*" element={<Navigate to="/" />} />
					</Routes>
					<Footer />
				</div>
			</IntlProvider>
		</Router>
	);
}

export default App;
