export const books = [
	{
		title: 'デジタル・ブランディング――世界のトップブランドがいま実践していること',
		author: 'パブロ・ルビオ・オルダス',
		asin: 'B0BKPQ8NB2',
		tags: ['branding'],
		image: 'https://m.media-amazon.com/images/I/71ljPfB-saL._SY522_.jpg',
		amazonUrl: 'https://amzn.to/48TJgmf',
		publishedDate: '2022-10-28',
	},
	{
		title: 'ABOUT FACE インタラクションデザインの本質（第四版）',
		author: 'Alan Cooper、Robert Reimann、David Cronin、Christopher Noessel',
		asin: 'B0D7FS5966',
		tags: ['interaction'],
		image: 'https://m.media-amazon.com/images/I/712COn0c3CL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3YUi1TN',
		publishedDate: '2024-08-14',
	},
	{
		title: 'みんなではじめるデザイン批評―目的達成のためのコラボレーション&コミュニケーション改善ガイド',
		author: 'アーロン・イリザリー（Aaron Irizarry）、アダム・コナー（Adam Connor）',
		asin: 'B01J2OEYLU',
		tags: ['review'],
		image: 'https://m.media-amazon.com/images/I/71oVKpSV9VL._SL1280_.jpg',
		amazonUrl: 'https://amzn.to/40OMKEz',
		publishedDate: '2016-07-26',
	},
	{
		title: 'オンスクリーン タイポグラフィ 事例と論説から考えるウェブの文字表現',
		author: '伊藤 庄平、佐藤 好彦、守友 彩子、桝田 草一、カワセ タケヒロ、ハマダ ナオフミ、きむ みんよん、関口 浩之、生明 義秀',
		asin: 'B08WCGDS3C',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/81ozQ3aR7uL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/40VtMfo',
		rakutenUrl: 'https://a.r10.to/hPzxtq',
		publishedDate: '2021-02-17',
	},
	{
		title: 'より良い世界のためのデザインー意味、持続可能性、人間性中心',
		author: 'D・ノーマン（ドナルド・ノーマン）',
		asin: '4788518279',
		tags: ['social'],
		image: 'https://m.media-amazon.com/images/I/81hYNSZ2CRL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/40OeXva',
		publishedDate: '2023-10-12',
	},
	{
		title: 'はじめてのUIデザイン 改訂版',
		author: '池田 拓司、宇野 雄、上ノ郷谷 太一、坪田 朋、元山 和之、吉竹 遼',
		asin: 'B087BMJ1Q6',
		tags: ['ui'],
		image: 'https://m.media-amazon.com/images/I/81rpWqwfAJL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3ANIE4S',
		publishedDate: '2020-05-01',
	},
	{
		title: '融けるデザイン ―ハード×ソフト×ネット時代の新たな設計論',
		author: '渡邊 恵太',
		asin: 'B00TPLKYEM',
		tags: ['ui', 'interaction'],
		image: 'https://m.media-amazon.com/images/I/71-R9+XyvfL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/40WQ5RV',
		publishedDate: '2015-02-18',
	},
	{
		title: '縁の下のUIデザイン──小さな工夫で大きな効果をもたらす実践TIPS＆テクニック',
		author: '池田 拓司',
		asin: 'B0C1MZ8NLB',
		tags: ['ui'],
		image: 'https://m.media-amazon.com/images/I/715STra2LqL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3AQVJdH',
		publishedDate: '2023-04-21',
	},
	{
		title: '日本語組版入門: その構造とアルゴリズム',
		author: '向井 裕一',
		asin: '4416618689',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/71LBN7odFgL._SL1200_.jpg',
		amazonUrl: 'https://amzn.to/3AEtPlg',
		publishedDate: '2018-06-08',
	},
	{
		title: 'タイポグラフィの基礎―知っておきたい文字とデザインの新教養',
		author: '小宮山 博史',
		asin: '441661022X',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/91W85UHIoXL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3YSK0Dn',
		publishedDate: '2010-08-01',
	},
	{
		title: '欧文書体―その背景と使い方 (新デザインガイド)',
		author: '小林 章',
		asin: '4568502772',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/41OKbfK2WQL.jpg',
		amazonUrl: 'https://amzn.to/3YUbdpo',
		publishedDate: '2005-06-16',
	},
	{
		title: '欧文書体 2 定番書体と演出法 (タイポグラフィの基本BOOK)',
		author: '小林 章',
		asin: '4568503647',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/41U7YgVpVwL.jpg',
		amazonUrl: 'https://amzn.to/3ACRJO5',
		publishedDate: '2005-06-16',
	},
	{
		title: 'Adobe InDesign 文字組み徹底攻略ガイド 第3版',
		author: '大橋 幸二',
		asin: '4862670806',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/514B6X9s9XL.jpg',
		amazonUrl: 'https://amzn.to/3AOPN4Y',
		publishedDate: '2010-01-21',
	},
	{
		title: 'タイポグラフィの基本ルール ―プロに学ぶ、一生枯れない永久不滅テクニック―',
		author: '大崎 善治',
		asin: 'B01M721DIP',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/91i6H6krUGL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3YNwzok',
		publishedDate: '2010-11-29',
	},
	{
		title: 'タイポグラフィ論考',
		author: 'エリック・ギル',
		asin: 'B0BGGMT2BM',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/617Lfxn3CQL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3CtWBW4',
		publishedDate: '2022-10-26',
	},
	{
		title: 'タイポグラフィ・ベイシック',
		author: '高田 雄吉',
		asin: '',
		isbn: '475625084X',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/61H3rcbLBvL._SL1413_.jpg',
		amazonUrl: 'https://amzn.to/3AN6LAM',
		publishedDate: '2018-04-20',
	},
	{
		title: '遊びある真剣、真剣な遊び、私の人生 解題：美学としてのグリッドシステム',
		author: 'ヨゼフ・ミューラー＝ブロックマン',
		asin: '',
		isbn: '4802511035',
		tags: ['graphic', 'grid'],
		image: 'https://m.media-amazon.com/images/I/61QqUjGdFsL._SL1280_.jpg',
		amazonUrl: 'https://amzn.to/3UZmPGk',
		publishedDate: '2018-05-24',
	},
	{
		title: '遊び心のあるデザイン 視線を勝ち取る「ウィット」なアイデア',
		author: 'ベリル・マカルホーン、デイヴィッド・スチュアート、グレッグ・クゥイントン、ニック・アズベリー',
		asin: '',
		isbn: '4802511272',
		tags: ['graphic'],
		image: 'https://m.media-amazon.com/images/I/71d37rxHsOL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3UZmPGk',

		publishedDate: '2018-12-21',
	},
	{
		title: 'レタースペーシング タイポグラフィにおける文字間調整の考え方',
		author: '今市達也',
		asin: '',
		isbn: '4802512090',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/7169N6kGSjS._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/40TtsOg',
		publishedDate: '2021-07-21',
	},
	{
		title: 'タイポグラフィ・ハンドブック 第2版',
		author: '小泉 均、akira1975',
		asin: '',
		isbn: '432737749X',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/61VllVa5wtL._SL1000_.jpg',
		amazonUrl: 'https://amzn.to/4hWwxmM',
		publishedDate: '2021-07-26',
	},
	{
		title: 'ちいさくはじめるデザインシステム',
		author: '大塚亜周、稲葉志奈、金森悠、samemaru、圓山伊吹、植田将基、関口裕、8chari、後藤拓也、小木曽槙一、桝田草一',
		asin: 'B0BXD2NRHF',
		isbn: '',
		tags: ['designSystem', 'ui'],
		image: 'https://m.media-amazon.com/images/I/61BNQMyoiEL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3Zg0AP9',
		publishedDate: '2023-03-15',
	},
	{
		title: '現場のプロがわかりやすく教えるUI/UXデザイナー養成講座',
		author: '本末英樹',
		asin: 'B0BZ7XR9LQ',
		isbn: '',
		tags: ['ui', 'ux'],
		image: 'https://m.media-amazon.com/images/I/71tNPhuPM0L._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3CAfAyl',
		publishedDate: '2023-03-27',
	},
	{
		title: 'オブジェクト指向UIデザイン──使いやすいソフトウェアの原理',
		author: 'ソシオメディア株式会社、上野 学、藤井 幸多',
		asin: 'B0893RK6WC',
		isbn: '',
		tags: ['ui'],
		image: 'https://m.media-amazon.com/images/I/71-0gAbCw0L._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3V1osDC',
		publishedDate: '2020-06-05',
	},
	{
		title: 'UIデザイン必携 ユーザーインターフェースの設計と改善を成功させるために',
		author: '原田 秀司',
		asin: 'B09T397W9L',
		isbn: '',
		tags: ['ui'],
		image: 'https://m.media-amazon.com/images/I/81HqlmGgGfL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/40Wg8sA',
		publishedDate: '2022-04-13',
	},
	{
		title: 'UIデザインの教科書［新版］ マルチデバイス時代のインターフェース設計',
		author: '原田 秀司',
		asin: 'B07KXNKRMM',
		isbn: '',
		tags: ['ui'],
		image: 'https://m.media-amazon.com/images/I/815PJDcFTlL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3CPhLhq',
		publishedDate: '2019-01-21',
	},
	{
		title: 'UXデザインの教科書',
		author: '安藤昌也',
		asin: 'B09993DLMS',
		isbn: '',
		tags: ['ux'],
		image: 'https://m.media-amazon.com/images/I/71o6n5NgjlL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3ZeuuTU',
		publishedDate: '2016-05-30',
	},
	{
		title: '誰のためのデザイン？　増補・改訂版　―認知科学者のデザイン原論',
		author: 'D. A. ノーマン',
		asin: '4788514346',
		isbn: '',
		tags: ['ux'],
		image: 'https://m.media-amazon.com/images/I/717j68MWouL._SL1000_.jpg',
		amazonUrl: 'https://amzn.to/3CAownn',
		publishedDate: '2015-04-23',
	},
	{
		title: 'なるほどデザイン',
		author: '筒井 美希',
		asin: 'B012VJNW6Q',
		isbn: '',
		tags: ['graphic'],
		image: 'https://m.media-amazon.com/images/I/71qPIMl1+fL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/4eB6jDD',
		publishedDate: '2015-07-31',
	},
	{
		title: '問いのデザイン 創造的対話のファシリテーション',
		author: '安斎勇樹、塩瀬隆之',
		asin: 'B0BJL1TVXV',
		isbn: '',
		tags: ['facilitation', 'workshop'],
		image: 'https://m.media-amazon.com/images/I/7135Qwz0-3L._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3V5D0Sz',
		publishedDate: '2020-06-10',
	},
	{
		title: 'How to Design　いちばん面白いデザインの教科書　改訂版',
		author: 'カイシ トモヤ',
		asin: 'B06XRR4QH4',
		isbn: '',
		tags: ['graphic'],
		image: 'https://m.media-amazon.com/images/I/81yWGEB+U3L._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/48YJdp0',
		publishedDate: '2017-03-22',
	},
	{
		title: '1冊ですべて身につくHTML ＆ CSSとWebデザイン入門講座［第2版］',
		author: 'Mana',
		asin: 'B0CTM5Z6XQ',
		isbn: '',
		tags: ['web'],
		image: 'https://m.media-amazon.com/images/I/81BHtZPjuvL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/4fUW3qB',
		publishedDate: '2024-03-02',
	},
	{
		title: 'デザインリサーチの教科書',
		author: '木浦幹雄',
		asin: 'B08MZQ9X1B',
		isbn: '',
		tags: ['research'],
		image: 'https://m.media-amazon.com/images/I/81LRP3CS1FL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3B42zMW',
		publishedDate: '2020-11-17',
	},
	{
		title: 'ノンデザイナーズ・デザインブック　［第4版］',
		author: 'Robin Williams',
		asin: 'B01LW1BC2L',
		isbn: '',
		tags: ['graphic', 'web', 'typography'],
		image: 'https://m.media-amazon.com/images/I/71cin2YZvcL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/40Zf3A6',
		publishedDate: '2016-09-20',
	},
	{
		title: 'デザイナーズ ハンドブック',
		author: 'パイ インターナショナル',
		asin: 'B06XGZZQ65',
		isbn: '',
		tags: ['graphic', 'dtp'],
		image: 'https://m.media-amazon.com/images/I/71kTEOD9oKL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/4fOr0Nu',
		publishedDate: '2012-11-15',
	},
	{
		title: '誰も教えてくれないデザインの基本 最新版',
		author: '細山田デザイン事務所',
		asin: 'B0C32PV4Q4',
		isbn: '',
		tags: ['graphic', "dtp"],
		image: 'https://m.media-amazon.com/images/I/71DVJcCs4aL._SL1500_.jpg',
		amazonUrl: "https://amzn.to/4fHB2jO",
		publishedDate: '2023-04-21'
	},
	{
		title: '伝わるデザインの基本 増補改訂3版 よい資料を作るためのレイアウトのルール',
		author: '高橋 佑磨, 片山 なつ',
		asin: 'B091YQ2QQY',
		isbn: '',
		tags: ['graphic', 'dtp', 'layout'],
		image: 'https://m.media-amazon.com/images/I/81YEN-W3JcS._SL1500_.jpg',
		amazonUrl: "https://amzn.to/4fWZEVd",
		publishedDate: '2021-04-13'
	},
	{
		title: 'レイアウト・デザインの教科書',
		author: '米倉 明男, 生田 信一, 青柳 千郷（ベーコン）',
		asin: 'B07NYN1681',
		isbn: '',
		tags: ['graphic', 'layout'],
		image: 'https://m.media-amazon.com/images/I/71AcEWq0-YL._SL1500_.jpg',
		amazonUrl: "https://amzn.to/3V1zCIw",
		publishedDate: '2019-02-22'
	},
	{
		title: 'UXデザインの法則 ―最高のプロダクトとサービスを支える心理学',
		author: 'Jon Yablonski',
		asin: '',
		isbn: '4873119499',
		tags: ['ux', 'service', 'psychology'],
		image: 'https://m.media-amazon.com/images/I/81+k11c4oES._SL1500_.jpg',
		amazonUrl: "https://amzn.to/48WSjml",
		publishedDate: '2021-05-18'
	},
	{
		title: 'インタフェースデザインの心理学 第2版 ―ウェブやアプリに新たな視点をもたらす100の指針',
		author: 'Susan Weinschenk',
		asin: '',
		isbn: '4873119456',
		tags: ['ux', 'psychology', 'ui'],
		image: 'https://m.media-amazon.com/images/I/61-O748Mi1L._SL1280_.jpg',
		amazonUrl: 'https://amzn.to/3V5GKn5',
		publishedDate: '2021-04-13'
	},
	{
		title: 'プロダクトデザインのためのストーリーテリング ―「物語」で魅了するユーザーエクスペリエンスを生み出す',
		author: 'Anna Dahlstroem',
		asin: '',
		isbn: '4814400683',
		tags: ['ux', 'storytelling'],
		image: 'https://m.media-amazon.com/images/I/61i-I5C8VdL._SL1419_.jpg',
		amazonUrl: 'https://amzn.to/3YZwH4j',
		publishedDate: '2024-05-28'
	},
	{
		title: 'エモーショナル・デザイン―微笑を誘うモノたちのために',
		author: 'ドナルド・A. ノーマン',
		asin: '',
		isbn: '4788509210',
		tags: ['ux'],
		image: 'https://m.media-amazon.com/images/I/81OM-0O1jUL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3ZeZSSc',
		publishedDate: '2004-10-15'
	},
	{
		title: 'インフォグラフィック制作ガイド　「関係」を可視化する情報デザインの手引き',
		author: '櫻田 潤',
		asin: 'B0CW1KB49Y',
		isbn: '',
		tags: ['infographic'],
		image: 'https://m.media-amazon.com/images/I/81rjOiToVtL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3YY3VRw',
		publishedDate: '2024-03-21'
	},
	{
		title: 'たのしいインフォグラフィック入門',
		author: '櫻田 潤',
		asin: 'B00SH1WO9E',
		isbn: '',
		tags: ['infographic'],
		image: 'https://m.media-amazon.com/images/I/717k72vdtnL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3Zh3lQg',
		publishedDate: '2015-01-30'
	},
	{
		title: 'インフォグラフィックスの潮流：情報と図解の近代史',
		author: '永原 康史',
		asin: 'B0811L94FS',
		isbn: '',
		tags: ['infographic'],
		image: 'https://m.media-amazon.com/images/I/81eOGOGYPRL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3YZxvpR',
		publishedDate: '2016-02-01'
	},
	{
		title: 'ふつうのデザイナーのためのタイポグラフィが上手くなる本',
		author: '加納 佑輔, 佐藤 雅尚',
		asin: 'B0C3VJYCTT',
		isbn: '',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/81cgCRpzJrL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3CCvFDD',
		publishedDate: '2023-07-05'
	},
	{
		title: 'タイポグラフィで魅せる広告デザイン',
		author: 'パイ インターナショナル',
		asin: 'B0B5D184XZ',
		isbn: '',
		tags: ['typography', 'advertising'],
		image: 'https://m.media-amazon.com/images/I/81QknTwCQvL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3V0SpUe',
		publishedDate: '2022-02-23'
	},
	{
		title: '新標準・欧文タイポグラフィ入門　プロのための欧文デザイン＋和欧混植',
		author: 'アンドリュー・ポセケリ, 生田 信一, コン トヨコ, 川下 城誉',
		asin: 'B08H1LR8S8',
		isbn: '',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/81yQcRUTIjL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/40SC73B',
		publishedDate: '2020-08-25'
	},
	{
		title: 'サービスデザインの教科書',
		author: '武山政直',
		asin: 'B077GKKFB8',
		isbn: '',
		tags: ['service'],
		image: 'https://m.media-amazon.com/images/I/91peDHsjALL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3CEfaHn',
		publishedDate: '2017-09-11'
	},
	{
		title: '配色の設計 ―色の知覚と相互作用 Interaction of Color',
		author: 'ジョセフ・アルバース(Josef Albers), 永原康史(監訳), 和田美樹',
		asin: '',
		isbn: '4802510241',
		tags: ['color'],
		image: 'https://m.media-amazon.com/images/I/61GSLR-EB2L._SL1280_.jpg',
		amazonUrl: 'https://amzn.to/3Oj5kx3',
		publishedDate: '2016-06-24'
	},
	{
		title: 'デザインシステムの育て方　継続的な進化と改善のためのアプローチ',
		author: 'ダン・モール',
		asin: 'B0DDSFQGTF',
		isbn: '',
		tags: ['designSystem'],
		image: 'https://m.media-amazon.com/images/I/816OHrPxGAL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3YYhDUr',
		publishedDate: '2024-08-28'
	},
	{
		title: 'UXデザイン100の原則',
		author: 'アイリーン・ペレイラ',
		asin: 'B0CJ4XN2VY',
		isbn: '',
		tags: ['ux'],
		image: 'https://m.media-amazon.com/images/I/71PQJH+0wbL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/40YxF3o',
		publishedDate: '2023-09-21'
	},
	{
		title: 'デザインはストーリーテリング　「体験」を生み出すためのデザインの道具箱',
		author: 'エレン・ラプトン',
		asin: 'B07J62CY6H',
		isbn: '',
		tags: ['storytelling'],
		image: 'https://m.media-amazon.com/images/I/813vquYa8JL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/4eDV8dd',
		publishedDate: '2018-10-15'
	},
	{
		title: 'IA100　ユーザーエクスペリエンスデザインのための情報アーキテクチャ設計',
		author: '長谷川 敦士',
		asin: 'B087X497LT',
		isbn: '',
		tags: ['ia'],
		image: 'https://m.media-amazon.com/images/I/81X+p6NNFQL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3APBSvA',
		publishedDate: '2009-10-28'
	},
	{
		title: '今日からはじめる情報設計　センスメイキングするための7ステップ',
		author: 'アビー・コバート',
		asin: 'B07JPJWX45',
		isbn: '',
		tags: ['ia'],
		image: 'https://m.media-amazon.com/images/I/81V+IDgUjPL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/4g0Rxal',
		publishedDate: '2015-10-22'
	},
	{
		title: '情報アーキテクチャ 第4版 ―見つけやすく理解しやすい情報設計',
		author: 'Louis Rosenfeld, Peter Morville, Jorge Arango',
		asin: '',
		isbn: '4873117720',
		tags: ['ia'],
		image: 'https://m.media-amazon.com/images/I/91o2mmnD4xL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3Z21lKp',
		publishedDate: '2016-11-18'
	},
	{
		title: 'フォントの話をしよう',
		author: 'パイ インターナショナル, 佐々木俊',
		asin: 'B09NY81F8J',
		isbn: '',
		tags: ['typography', 'font'],
		image: 'https://m.media-amazon.com/images/I/71d308iH0lL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3Ol0jEm',
		publishedDate: '2023-08-31'
	},
	{
		title: '目的で探すフォント見本帳',
		author: 'タイポグラフィ・ブックス編集部',
		asin: 'B07R4989SN',
		isbn: '',
		tags: ['typography', 'font'],
		image: 'https://m.media-amazon.com/images/I/81La7HFL4oL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/4fHZXDF',
		publishedDate: '2019-05-21'
	},
	{
		title: '企業・ブランド・プロジェクトの個性を伝える　オリジナルフォントとデザイン事例54',
		author: 'MdN編集部',
		asin: 'B0CYZPDG74',
		isbn: '',
		tags: ['font', 'typography'],
		image: 'https://m.media-amazon.com/images/I/71XsK7In0zL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3CxVVPu',
		publishedDate: '2024-03-25'
	},
	{
		title: '新装復刻版 現代図案文字大集成 (青幻舎ビジュアル文庫シリーズ)',
		author: '辻 克己',
		asin: '',
		isbn: '4861524679',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/71HjYKj++aL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/4fFcYhc',
		publishedDate: '2014-11-05'
	},
	{
		title: '新装復刻版 変体英文字図案集 (青幻舎ビジュアル文庫シリーズ)',
		author: '大沼知之',
		asin: '',
		isbn: '4861526914',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/71BCsZc9qfL._SL1412_.jpg',
		amazonUrl: 'https://amzn.to/3YYaPGh',
		publishedDate: '2018-08-30'
	},
	{
		title: '実用手描文字 『実用図案文字と意匠』新装改訂復刻版 (青幻舎ビジュアル文庫シリーズ)',
		author: '姉崎 正広',
		asin: '',
		isbn: '4861526361',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/61lOXpkq4aL._SL1000_.jpg',
		amazonUrl: 'https://amzn.to/3ZiDYO0',
		publishedDate: '2017-08-30'
	},
	{
		title: '大正タイポグラフィ',
		author: '藤原太一',
		asin: '',
		isbn: '4837309518',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/71h7xht9TrL._SL1400_.jpg',
		amazonUrl: 'https://amzn.to/40YN6IF',
		publishedDate: '2019-03-20'
	},
	{
		title: '組版造形 タイポグラフィ名作精選',
		author: '白井敬尚',
		asin: '',
		isbn: '4766136543',
		tags: ['typography'],
		image: 'https://m.media-amazon.com/images/I/71wiQoqv9fL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/4g2pVBH',
		publishedDate: '2024-03-08'
	},
	{
		title: '生きのびるためのデザイン',
		author: 'ヴィクター・パパネック',
		asin: '',
		isbn: '4794971850',
		tags: ['social', 'economy'],
		image: 'https://m.media-amazon.com/images/I/6158Uu6xmeL._SL1120_.jpg',
		amazonUrl: 'https://amzn.to/3UZTXhl',
		publishedDate: '2020-07-14'
	},
	{
		title: 'ザ・ダークパターン ユーザーの心や行動をあざむくデザイン',
		author: '仲野 佑希',
		asin: 'B0B5X6WHDB',
		isbn: '',
		tags: ['ux', 'ui'],
		image: 'https://m.media-amazon.com/images/I/81n3ovqmgzL._SL1500_.jpg',
		amazonUrl: 'https://amzn.to/3ZiU4XQ',
		publishedDate: '2022-08-03'
	},
	{
		"title": "世界最高峰の美術大学セントラル・セント・マーチンズで学ぶ デザイン・アートの基礎課程　発想力、共感力、批判的思考、問題解決能力を身につける",
		"author": "ルーシー・アレクサンダー, ティモシー・ミーラ",
		"asin": "B0BHD532X7",
		"isbn": "",
		"tags": ['graphic'],
		"image": "https://m.media-amazon.com/images/I/81j4TEFdEYL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3CJO29T",
		"publishedDate": "2022-10-20"
	},
	{
		"title": "究極のロゴデザイン： 精鋭クリエイティブディレクター、アートディレクターの思考と表現から学ぶ。 Designer's Collection",
		"author": "デザインノート編集部",
		"asin": "B0B1TVJTWN",
		"isbn": "",
		"tags": ['logo'],
		"image": "https://m.media-amazon.com/images/I/71YzjJ4kLHL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/40Y6kye",
		"publishedDate": "2022-05-09"
	},
	{
		"title": "デザイナーは何を考え、どう作っていくのか？ WebデザインプロセスBook",
		"author": "加藤千歳",
		"asin": "B0DK8SHQ7P",
		"isbn": "",
		"tags": ['web'],
		"image": "https://m.media-amazon.com/images/I/815lJZ2lnnL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3Z9kGci",
		"publishedDate": "2024-10-29"
	},
	{
		"title": "最強構図　知ってたらデザインうまくなる。",
		"author": "ingectar-e",
		"asin": "B0BS61CGSR",
		"isbn": "",
		"tags": ['layout'],
		"image": "https://m.media-amazon.com/images/I/71oSJwx5zoL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4ePTipR",
		"publishedDate": "2023-01-26"
	},
	{
		"title": "けっきょく、よはく。余白を活かしたデザインレイアウトの本",
		"author": "ingectar-e",
		"asin": "B07FW35F5R",
		"isbn": "",
		"tags": ['layout'],
		"image": "https://m.media-amazon.com/images/I/71TGBCxqprL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4eLOn99",
		"publishedDate": "2018-07-20"
	},
	{
		"title": "とりあえず、素人っぽく見えないデザインのコツを教えてください！",
		"author": "ingectar-e",
		"asin": "B09PTNJPWY",
		"isbn": "",
		"tags": ['graphic'],
		"image": "https://m.media-amazon.com/images/I/71QV9fWWy5L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4fMnp2B",
		"publishedDate": "2022-01-19"
	},
	{
		"title": "サクッと学べるデザイン心理法則108",
		"author": "321web（三井 将之）",
		"asin": "B0BQLZVCD6",
		"isbn": "",
		"tags": ['psychology'],
		"image": "https://m.media-amazon.com/images/I/71dHdCURt+L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4fKdYRm",
		"publishedDate": "2023-02-06"
	},
	{
		"title": "ロゴデザイン研究　100の実例に学ぶ最適解を探し出すアプローチ",
		"author": "佐藤浩二",
		"asin": "B0CQQBYYZH",
		"isbn": "",
		"tags": ['logo'],
		"image": "https://m.media-amazon.com/images/I/81c2YY2w56L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4g6GE6N",
		"publishedDate": "2023-12-22"
	},
	{
		"title": "デザインのミカタ　無限の「ひきだし」と「センス」を手に入れる",
		"author": "デザイン研究所",
		"asin": "B0CC8H434L",
		"isbn": "",
		"tags": ['graphic'],
		"image": "https://m.media-amazon.com/images/I/61jDUqySWzL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3B1mAE0",
		"publishedDate": "2023-08-02"
	},
	{
		"title": "あるあるレイアウト　すぐに使えて素敵に仕上がるデザインカタログ集",
		"author": "ingectar-e",
		"asin": "B095VWN7G9",
		"isbn": "",
		"tags": ['layout'],
		"image": "https://m.media-amazon.com/images/I/81HbSbDVSFS._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4iatGaa",
		"publishedDate": "2021-05-28"
	},
	{
		"title": "思わずクリックしたくなる バナーデザインのきほん",
		"author": "カトウヒカル",
		"asin": "B09QGHH7ZW",
		"isbn": "",
		"tags": ['graphic'],
		"image": "https://m.media-amazon.com/images/I/81V5V6vmsdL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3AVXPJr",
		"publishedDate": "2022-02-09"
	},
	{
		"title": "あるあるタイポ。誰でもできる文字加工のデザイン集",
		"author": "ingectar-e",
		"asin": "B0B4C4RNZM",
		"isbn": "",
		"tags": ['typography'],
		"image": "https://m.media-amazon.com/images/I/71VUaq-bknL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4hZQucm",
		"publishedDate": "2022-06-24"
	},
	{
		"title": "Figma for デザインシステム デザインを中心としたプロダクト開発の仕組み作り",
		"author": "沢田 俊介",
		"asin": "B0CTZKF44R",
		"isbn": "",
		"tags": ['figma', 'designSystem'],
		"image": "https://m.media-amazon.com/images/I/81S5iNTgWpL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3Zbn2HM",
		"publishedDate": "2024-04-05"
	},
	{
		"title": "「売る」から､「売れる」へ。水野学のブランディングデザイン講義",
		"author": "水野 学",
		"asin": "B01M4OHC5N",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/61JeGikyxlL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4g5W3En",
		"publishedDate": "2015-05-06"
	},
	{
		"title": "ブランディングデザインの教科書",
		"author": "西澤明洋",
		"asin": "B0928F7S75",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/81mqyEhAS4L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4gp6W4z",
		"publishedDate": "2023-08-31"
	},
	{
		"title": "Figma for UIデザイン［日本語版対応］ アプリ開発のためのデザイン、プロトタイプ、ハンドオフ",
		"author": "沢田 俊介",
		"asin": "B0BHYXZCHG",
		"isbn": "",
		"tags": ['figma'],
		"image": "https://m.media-amazon.com/images/I/811w1yvmURL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4g6CcF3",
		"publishedDate": "2022-11-14"
	},
	{
		"title": "Figmaで作るUIデザインアイデア集 サンプルで学ぶ35のパターン",
		"author": "相原 典佳, 岡部 千幸",
		"asin": "B0DBHCZ392",
		"isbn": "",
		"tags": ['figma', 'ui'],
		"image": "https://m.media-amazon.com/images/I/71UkCY3pa3L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/41637MZ",
		"publishedDate": "2024-07-23"
	},
	{
		"title": "これからはじめるFigma Web・UIデザイン入門",
		"author": "阿部 文人, 今 聖菜, 田口 冬菜, 中川 小雪",
		"asin": "B0BFGZLRZH",
		"isbn": "",
		"tags": ['figma'],
		"image": "https://m.media-amazon.com/images/I/81v8KZ6CGOL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4g8KYmb",
		"publishedDate": "2022-09-26"
	},
	{
		"title": "はじめてでも迷わないFigmaのきほん やさしく学べるWebサイト・バナーデザイン入門",
		"author": "もち",
		"asin": "B0CC96VRSP",
		"isbn": "",
		"tags": ['figma'],
		"image": "https://m.media-amazon.com/images/I/819i1Co6PpL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3Or9R0s",
		"publishedDate": "2023-07-12"
	},
	{
		"title": "初心者からちゃんとしたプロになる　Figma基礎入門",
		"author": "相原典佳, 沖 良矢, 倉又美樹, 岡部千幸",
		"asin": "B0C5L7NHYT",
		"isbn": "",
		"tags": ['figma'],
		"image": "https://m.media-amazon.com/images/I/81+ecAEw-dL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3ZqFwW4",
		"publishedDate": "2023-05-26"
	},
	{
		"title": "Figma for UIデザイン アプリ開発のためのデザイン、プロトタイプ、ハンドオフ",
		"author": "沢田 俊介",
		"asin": "B0B215PRN2",
		"isbn": "",
		"tags": ['figma'],
		"image": "https://m.media-amazon.com/images/I/71ZxvW4v4rL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4eNhgC8",
		"publishedDate": "2022-06-10"
	},
	{
		"title": "Figmaデザイン入門〜UIデザイン、プロトタイピングからチームメンバーとの連携まで〜",
		"author": "綿貫 佳祐",
		"asin": "B0BT1BR5DH",
		"isbn": "",
		"tags": ['figma'],
		"image": "https://m.media-amazon.com/images/I/71IP-0DrZ-L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4i47Q83",
		"publishedDate": "2023-02-08"
	},
	{
		"title": "FigmaでUIデザイン",
		"author": "藤崎 猫",
		"asin": "B0CW1MWJQ1",
		"isbn": "",
		"tags": ['figma', 'ui'],
		"image": "https://m.media-amazon.com/images/I/81Nc2z2IA+L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3ZaeIrW",
		"publishedDate": "2024-02-24"
	},
	{
		"title": "ブランディングの科学　誰も知らないマーケテイングの法則11",
		"author": "バイロン・シャープ",
		"asin": "B07GXKC3SG",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/A1C8-zXX2EL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/41aPigj",
		"publishedDate": "2018-07-06"
	},
	{
		"title": "ブランディングの教科書: ブランド戦略の理論と実践がこれ一冊でわかる",
		"author": "羽田康祐 k_bird 他, O.SASAKI, M.MIZUTAMARI, T.NAKANO, T.UMENO, J.SEKIGUCHI, R.MURATA, M.HIRAMATSU",
		"asin": "B08CSMY456",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/810Ok9rWtVL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3Or1amX",
		"publishedDate": "2020-08-01"
	},
	{
		"title": "ストーリーブランディング１００の法則",
		"author": "川上徹也",
		"asin": "B0C4KQZ49W",
		"isbn": "",
		"tags": ['branding', 'storytelling'],
		"image": "https://m.media-amazon.com/images/I/71YaOncGdML._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3VdH03o",
		"publishedDate": "2023-04-26"
	},
	{
		"title": "ブランディング・ファースト ――広告費をかける前に「ブランド」をつくる",
		"author": "宮村岳志",
		"asin": "B0891LMQK3",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/711E7FyAi2L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3VcfNhv",
		"publishedDate": "2020-04-24"
	},
	{
		"title": "デジタル時代の基礎知識『ブランディング』 「顧客体験」で差がつく時代の新しいルール（MarkeZine BOOKS）",
		"author": "山口 義宏",
		"asin": "B078X9VTX2",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/81Gp1zC1cgL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3AXrDW5",
		"publishedDate": "2018-03-15"
	},
	{
		"title": "ブランディングの科学　新市場開拓篇　－エビデンスに基づいたブランド成長の新法則－",
		"author": "バイロン・シャープ, ジェニー・ロマニウク",
		"asin": "B08G1C1BY4",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/81KG69jlg4L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4fJ76DN",
		"publishedDate": "2020-08-20"
	},
	{
		"title": "サクッとわかる ビジネス教養 　ブランディング",
		"author": "田中洋",
		"asin": "B0D2TYLJHS",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/81DM8fpcyJL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3COFlen",
		"publishedDate": "2024-08-14"
	},
	{
		"title": "西澤明洋の成功するブランディングデザイン：本当に良いモノをより良いカタチに導く デザインノートBOOK",
		"author": "デザインノート編集部",
		"asin": "B0DJ1W3VYD",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/71HSDR1XTbL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3OqKwUx",
		"publishedDate": "2024-06-14"
	},
	{
		"title": "ブランディングデザイン　ユニーク・広がる・機能するデザインの考え方",
		"author": "下川 大助",
		"asin": "B0D7VGLS67",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/718I+J0znbL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3ZpN1MS",
		"publishedDate": "2024-06-28"
	},
	{
		"title": "ターゲットの心を掴む スタイルのあるブランディングデザイン",
		"author": "パイ インターナショナル",
		"asin": "B09C8395XJ",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/817lMIiA+uL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3OuKaMq",
		"publishedDate": "2021-04-14"
	},
	{
		"title": "欲しくなるパッケージのデザインとブランディング",
		"author": "パイ インターナショナル",
		"asin": "B085HCFJJ5",
		"isbn": "",
		"tags": ['branding', 'packaging'],
		"image": "https://m.media-amazon.com/images/I/81pS89XC65L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/49rERHz",
		"publishedDate": "2019-12-09"
	},
	{
		"title": "見てわかる、迷わず決まる配色アイデア 3色だけでセンスのいい色 見てわかる、迷わず決まるシリーズ",
		"author": "ingectar-e",
		"asin": "B089VPT2D7",
		"isbn": "",
		"tags": ['color'],
		"image": "https://m.media-amazon.com/images/I/71hqzQ67VFL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3CM7TVL",
		"publishedDate": "2020-06-12"
	},
	{
		"title": "いろいろな、いろ。配色に着目したデザインレイアウトの本",
		"author": "ingectar-e",
		"asin": "B09MT6CBKJ",
		"isbn": "",
		"tags": ['color'],
		"image": "https://m.media-amazon.com/images/I/71EX91RFZfL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4ga26Ic",
		"publishedDate": "2021-12-03"
	},
	{
		"title": "「悩まない」配色の基本 好きな色から考える",
		"author": "細山田デザイン事務所",
		"asin": "B0CHHC71HX",
		"isbn": "",
		"tags": ['color'],
		"image": "https://m.media-amazon.com/images/I/71tzyn7AnWL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/49jrIQz",
		"publishedDate": "2023-11-07"
	},
	{
		"title": "色の大事典　基礎知識と配色・カラーチャート・伝統色・慣用色名　DIGITAL COLORS for DESIGN",
		"author": "井上のきあ",
		"asin": "B09JBSCDMM",
		"isbn": "",
		"tags": ['color'],
		"image": "https://m.media-amazon.com/images/I/81E4LzpghWL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4gaw66y",
		"publishedDate": "2021-09-17"
	},
	{
		"title": "COLOR DESIGN　カラー別配色デザインブック",
		"author": "ingectar-e",
		"asin": "B08V4RG64L",
		"isbn": "",
		"tags": ['color'],
		"image": "https://m.media-amazon.com/images/I/710MXdZvrYL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3Z71Nad",
		"publishedDate": "2021-02-10"
	},
	{
		"title": "見てわかる、迷わず決まる配色アイデア 3色だけでセンスのいい色PART2 見てわかる、迷わず決まるシリーズ",
		"author": "ingectar-e",
		"asin": "B0BLYMQT7S",
		"isbn": "",
		"tags": ['color'],
		"image": "https://m.media-amazon.com/images/I/71qCXQC1ziL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4g8v4rJ",
		"publishedDate": "2022-11-22"
	},
	{
		"title": "配色デザイン良質見本帳　イメージで探せて、すぐに使えるアイデア集",
		"author": "たじま ちはる",
		"asin": "B082XMY34X",
		"isbn": "",
		"tags": ['color'],
		"image": "https://m.media-amazon.com/images/I/81GILiWCxBS._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4i3tvxf",
		"publishedDate": "2019-12-19"
	},
	{
		"title": "美しい写真から広がる！　配色＆デザインアイデア事典",
		"author": "小林礼奈",
		"asin": "B0BSKT8WQQ",
		"isbn": "",
		"tags": ['color'],
		"image": "https://m.media-amazon.com/images/I/9106OuCt8XL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3OuF8j7",
		"publishedDate": "2023-02-01"
	},
	{
		"title": "配色アイデア手帖 めくって見つける新しいデザインの本［完全保存版］第2版",
		"author": "桜井 輝子",
		"asin": "B0C73M35CW",
		"isbn": "",
		"tags": ['color'],
		"image": "https://m.media-amazon.com/images/I/71moSkan12L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/49gm7uf",
		"publishedDate": "2023-07-27"
	},
	{
		"title": "配色アイデア手帖　世界を彩る色と文化　めくって旅する新しいデザインの本［完全保存版］",
		"author": "桜井 輝子",
		"asin": "B08CDH8Q5F",
		"isbn": "",
		"tags": ['color'],
		"image": "https://m.media-amazon.com/images/I/810Hm4aYRvL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3Or6zKK",
		"publishedDate": "2020-07-06"
	},
	{
		"title": "配色アイデア手帖 色とイラスト　かわいい世界観を作るヒントが詰まった本［完全保存版］",
		"author": "サタケ シュンスケ, 桜井 輝子",
		"asin": "B0CM2Y5DFQ",
		"isbn": "",
		"tags": ['color'],
		"image": "https://m.media-amazon.com/images/I/61+9BY8GVVL._SL1024_.jpg",
		"amazonUrl": "https://amzn.to/49awDDq",
		"publishedDate": "2023-12-21"
	},
	{
		"title": "売れるゲームのUI/UX 制作現場の舞台裏",
		"author": "株式会社Playce",
		"asin": "B0154COJFA",
		"isbn": "",
		"tags": ['ui', 'ux'],
		"image": "https://m.media-amazon.com/images/I/81ZHRXA6LcL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4eNflxb",
		"publishedDate": "2015-09-01"
	},
	{
		"title": "0から学ぶマテリアルデザイン2: Googleマテリアルデザインガイドライン日本語解説",
		"author": "UNI DESIGN",
		"asin": "B0BVZNQYCQ",
		"isbn": "",
		"tags": ['ui'],
		"image": "https://m.media-amazon.com/images/I/817EU5fCotL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3OuX3Gk",
		"publishedDate": "2023-02-15"
	},
	{
		"title": "フラットデザインで考える　新しいUIデザインのセオリー",
		"author": "宇野雄",
		"asin": "B00QT9Q96U",
		"isbn": "",
		"tags": ['ui'],
		"image": "https://m.media-amazon.com/images/I/71pmHsZB68L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/419Vy8e",
		"publishedDate": "2014-12-11"
	},
	{
		"title": "UI/UXデザインの原則",
		"author": "平石大祐",
		"asin": "B08N5V6BSJ",
		"isbn": "",
		"tags": ['ui', 'ux'],
		"image": "https://m.media-amazon.com/images/I/71l3MxIEKWL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4143isj",
		"publishedDate": "2020-10-23"
	},
	{
		"title": "デジタルメディアデザイン見本帳　WebデザインからUI・空間体験・メタバースまで",
		"author": "田渕将吾",
		"asin": "B0C6DVDZXD",
		"isbn": "",
		"tags": ['ui', 'web'],
		"image": "https://m.media-amazon.com/images/I/91sJ2jWbprL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3D17qPy",
		"publishedDate": "2023-06-02"
	},
	{
		"title": "UIデザイン　みんなで考え、カイゼンする。",
		"author": "栄前田 勝太郎, 河西 紀明, 西田 陽子",
		"asin": "B07PQF8TBW",
		"isbn": "",
		"tags": ['ui'],
		"image": "https://m.media-amazon.com/images/I/81iMRqmpDKL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3Va4eaI",
		"publishedDate": "2019-03-19"
	},
	{
		"title": "誰でもつくれる！UIデザイン入門: 機能するUIの基本と実践 Compass Booksシリーズ",
		"author": "ななうみ（著）／かいわれ（イラスト）",
		"asin": "B08DFKFFSV",
		"isbn": "",
		"tags": ['ui'],
		"image": "https://m.media-amazon.com/images/I/818M27bsxnL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3VcsclI",
		"publishedDate": "2020-07-30"
	},
	{
		"title": "つくって、みなおす、デザインシステム——現場での合意形成から設計、運用まで",
		"author": "株式会社ニジボックス",
		"asin": "B0DH7YFMP2",
		"isbn": "",
		"tags": ['ui', 'designSystem'],
		"image": "https://m.media-amazon.com/images/I/81sBUFVHmZL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/418iLaz",
		"publishedDate": "2024-10-02"
	},
	{
		"title": "【新版】UI GRAPHICS　成功事例と思想から学ぶ、これからのインターフェイスデザインとUX",
		"author": "安藤 剛, 水野 勝仁, 萩原 俊矢, ドミニク・チェン, 菅 俊一, 鹿野 護, 有馬 トモユキ, 渡邊 恵太, 須齋 佑紀／津﨑 将氏,",
		"asin": "B07JGSJGBV",
		"isbn": "",
		"tags": ['ui'],
		"image": "https://m.media-amazon.com/images/I/81dDAKRuefL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3B6K62r",
		"publishedDate": "2018-10-19"
	},
	{
		"title": "プロセス・オブ・UI/UX［UIデザイン編］ 実践形式で学ぶワイヤーフレームからビジュアルデザイン・開発連携まで",
		"author": "桂 信, 株式会社エクストーン",
		"asin": "B0CTHG9MTQ",
		"isbn": "",
		"tags": ['ui', 'ux'],
		"image": "https://m.media-amazon.com/images/I/81hN0HiH8gL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/414Vbf7",
		"publishedDate": "2024-05-22"
	},
	{
		"title": "実践ゲームUIデザイン コンセプト策定から実装のコツまで",
		"author": "太田垣 沙也子",
		"asin": "B09YTRT3KY",
		"isbn": "",
		"tags": ['ui'],
		"image": "https://m.media-amazon.com/images/I/71G1S4GdcrL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4g8OJYu",
		"publishedDate": "2022-06-22"
	},
	{
		"title": "Design Systems ―デジタルプロダクトのためのデザインシステム実践ガイド",
		"author": "Alla Kholmatova",
		"asin": "B07PTXXK1S",
		"isbn": "",
		"tags": ['designSystem'],
		"image": "https://m.media-amazon.com/images/I/81Z9r8EpavL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/49cyUhq",
		"publishedDate": "2018-12-25"
	},
	{
		"title": "Webアプリケーションアクセシビリティ──今日から始める現場からの改善 WEB+DB PRESS plus",
		"author": "伊原 力也, 小林 大輔, 桝田 草一, 山本 伶",
		"asin": "B0BTLCDDK5",
		"isbn": "",
		"tags": ['accessibility'],
		"image": "https://m.media-amazon.com/images/I/71LoaqB7sdL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4i9Cpt3",
		"publishedDate": "2023-02-27"
	},
	{
		"title": "モバイルアプリアクセシビリティ入門── iOS＋Androidのデザインと実装 Web＋DB PRESS plus",
		"author": "阿部 諒, 伊原 力也, 本田 雅人, めろん",
		"asin": "B0DKHFDW4Q",
		"isbn": "",
		"tags": ['accessibility'],
		"image": "https://m.media-amazon.com/images/I/81fy-HxBlXL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4fNH0zn",
		"publishedDate": "2024-11-21"
	},
	{
		"title": "カラー・アクセシビリティ (UX Design Books)",
		"author": "Geri Coady",
		"asin": "B0C7QFFB8M",
		"isbn": "",
		"tags": ['accessibility', 'color'],
		"image": "https://m.media-amazon.com/images/I/61pn70LLJVL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3B33JbM",
		"publishedDate": "2023-08-17"
	},
	{
		"title": "デザイニングWebアクセシビリティ - アクセシブルな設計やコンテンツ制作のアプローチ",
		"author": "太田良典, 伊原力也",
		"asin": "B01N3CGZ7W",
		"isbn": "",
		"tags": ['accessibility'],
		"image": "https://m.media-amazon.com/images/I/81RemwnnKkL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3OxwXTd",
		"publishedDate": "2015-07-27"
	},
	{
		"title": "見えにくい、読みにくい「困った！」を解決するデザイン【改訂版】",
		"author": "間嶋 沙知",
		"asin": "B0DD6MBH3Q",
		"isbn": "",
		"tags": ['accessibility'],
		"image": "https://m.media-amazon.com/images/I/811YCaaYdxL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/414G7y2",
		"publishedDate": "2024-09-24"
	},
	{
		"title": "コーディングWebアクセシビリティ - WAI-ARIAで実現するマルチデバイス環境のWebアプリケーション",
		"author": "ヘイドン・ピカリング",
		"asin": "4862462669",
		"isbn": "",
		"tags": ['accessibility'],
		"image": "https://m.media-amazon.com/images/I/71NvfwirL2L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3ZslJFP",
		"publishedDate": "2015-03-27"
	},
	{
		"title": "誰のためのアクセシビリティ？　障害のある人の経験と文化から考える",
		"author": "田中 みゆき",
		"asin": "B0CW1N2JW3",
		"isbn": "",
		"tags": ['accessibility'],
		"image": "https://m.media-amazon.com/images/I/71qG8+HQUqL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4ifocL1",
		"publishedDate": "2024-07-29"
	},
	{
		"title": "続・インタフェースデザインの心理学 ─ウェブやアプリに新たな視点をもたらす+100の指針",
		"author": "Susan Weinschenk",
		"asin": "",
		"isbn": "4873117712",
		"tags": ['psychology'],
		"image": "https://m.media-amazon.com/images/I/81ku2KHMsOL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/41a2PVy",
		"publishedDate": "2016-08-05"
	},
	{
		"title": "UIデザインの心理学―わかりやすさ・使いやすさの法則",
		"author": "Jeff Johnson",
		"asin": "4844337718",
		"isbn": "",
		"tags": ['psychology'],
		"image": "https://m.media-amazon.com/images/I/81g56xlYoLL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3CRbLFa",
		"publishedDate": "2015-03-13"
	},
	{
		"title": "デザイナーのための心理学",
		"author": "Joe Leech",
		"asin": "B0C816R7Z9",
		"isbn": "",
		"tags": ['psychology'],
		"image": "https://m.media-amazon.com/images/I/71kEiw5kCML._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/41bJZNU",
		"publishedDate": "2023-08-15"
	},
	{
		"title": "「人を動かす」広告デザインの心理術33",
		"author": "マルク・アンドルース, マテイス・ファン・レイヴェン, リック・ファン・バーレン",
		"asin": "B01LXPF53Q",
		"isbn": "",
		"tags": ['psychology', 'advertising'],
		"image": "https://m.media-amazon.com/images/I/71DlUMGpsQL._SL1280_.jpg",
		"amazonUrl": "https://amzn.to/4idTcuX",
		"publishedDate": "2016-10-01"
	},
	{
		"title": "スライドデザインの心理学 一発で決まるプレゼン資料の作り方",
		"author": "加藤 智也",
		"asin": "B00WR4J0OY",
		"isbn": "",
		"tags": ['psychology'],
		"image": "https://m.media-amazon.com/images/I/81+TXuDZgPL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/41c9yhM",
		"publishedDate": "2015-05-22"
	},
	{
		"title": "心を動かすデザインの秘密　認知心理学から見る新しいデザイン学",
		"author": "荷方 邦夫",
		"asin": "B07K3TR8GF",
		"isbn": "",
		"tags": ['psychology'],
		"image": "https://m.media-amazon.com/images/I/81d8fgtta5L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3CQeYVn",
		"publishedDate": "2014-06-10"
	},
	{
		"title": "［買わせる］の心理学　消費者の心を動かすデザインのしくみ67【改訂新版】",
		"author": "中村和正",
		"asin": "B0CP385J8L",
		"isbn": "",
		"tags": ['psychology'],
		"image": "https://m.media-amazon.com/images/I/71oQVgE5pLL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3CS53Pa",
		"publishedDate": "2023-12-08"
	},
	{
		"title": "行動を変えるデザイン ―心理学と行動経済学をプロダクトデザインに活用する",
		"author": "Stephen Wendel",
		"asin": "",
		"isbn": "4873119146",
		"tags": ['psychology'],
		"image": "https://m.media-amazon.com/images/I/61Ah5RFkEDL._SL1418_.jpg",
		"amazonUrl": "https://amzn.to/4gdxoO7",
		"publishedDate": "2020-06-11"
	},
	{
		"title": "[改訂版]ディテール・イン・タイポグラフィ 読みやすい欧文組版のための基礎知識",
		"author": "ヨースト・ホフリ",
		"asin": "",
		"isbn": "4909718028",
		"tags": ['typography'],
		"image": "https://m.media-amazon.com/images/I/61NPm1eF4YS._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/49hmK6U",
		"publishedDate": "2019-10-10"
	},
	{
		"title": "シュミット・タイポグラフィ ヘルムート・シュミット作品集",
		"author": "ヘルムート・シュミット",
		"asin": "",
		"isbn": "476613575X",
		"tags": ['typography'],
		"image": "https://m.media-amazon.com/images/I/81Rq4mN9o1L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3BasHpF",
		"publishedDate": "2022-02-08"
	},
	{
		"title": "モダン・タイポグラフィ 批判的タイポグラフィ史試論",
		"author": "ロビン・キンロス",
		"asin": "",
		"isbn": "4766132742",
		"tags": ['typography'],
		"image": "https://m.media-amazon.com/images/I/610G1bZcY5L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4eYxdWa",
		"publishedDate": "2020-11-10"
	},
	{
		"title": "ルーダー・タイポグラフィ ルーダー・フィロソフィ: エミール・ルーダー作品集",
		"author": "ヘルムート・シュミット",
		"asin": "",
		"isbn": "4416617518",
		"tags": ['typography'],
		"image": "https://m.media-amazon.com/images/I/812BmUY04PL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3OCxWl7",
		"publishedDate": "2017-08-03"
	},
	{
		"title": "街で出会った欧文書体実例集 -THE FIELD GUIDE TO TYPOGRAPHY",
		"author": "Peter Dawson",
		"asin": "",
		"isbn": "4861009537",
		"tags": ['typography'],
		"image": "https://m.media-amazon.com/images/I/81mvXhnK3NL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3VhLUMY",
		"publishedDate": "2015-08-25"
	},
	{
		"title": "デザイニング・インターフェース 第2版 ―パターンによる実践的インタラクションデザイン",
		"author": "Jenifer Tidwell",
		"asin": "",
		"isbn": "4873115310",
		"tags": ['interaction', 'ui', 'ux'],
		"image": "https://m.media-amazon.com/images/I/515dWeUst-L.jpg",
		"amazonUrl": "https://amzn.to/3CUl877",
		"publishedDate": "2011-12-24"
	},
	{
		"title": "マイクロインタラクション ―UI/UXデザインの神が宿る細部",
		"author": "Dan Saffer",
		"asin": "",
		"isbn": "4873116597",
		"tags": ['interaction', 'ux', 'ui'],
		"image": "https://m.media-amazon.com/images/I/910S4CNp06L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3VjzFiO",
		"publishedDate": "2014-03-19"
	},
	{
		"title": "英文サインのデザイン　利用者に伝わりやすい英文表示とは？",
		"author": "小林 章, 田代 眞理",
		"asin": "B07YXWYHBF",
		"isbn": "",
		"tags": ['typography'],
		"image": "https://m.media-amazon.com/images/I/81lNbGsRFrL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/41gKnKP",
		"publishedDate": "2019-10-23"
	},
	{
		"title": "デザイナーのためのプロトタイピング入門",
		"author": "キャスリン・マッケルロイ",
		"asin": "B07VYPY7BF",
		"isbn": "",
		"tags": ['ui', 'prototyping'],
		"image": "https://m.media-amazon.com/images/I/81eTVYggTGL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3OvM4fY",
		"publishedDate": "2019-07-23"
	},
	{
		"title": "The Elements of User Experience[リフロー版]: 5段階モデルで考えるUXデザイン",
		"author": "Jesse James Garrett",
		"asin": "B09ZXHTC2G",
		"isbn": "",
		"tags": ['ux'],
		"image": "https://m.media-amazon.com/images/I/81XDxV1-8OL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4gfdL8q",
		"publishedDate": "2022-07-15"
	},
	{
		"title": "増補改訂版 レイアウト基本の「き」",
		"author": "佐藤直樹(アジール)",
		"asin": "",
		"isbn": "4766130235",
		"tags": ['layout'],
		"image": "https://m.media-amazon.com/images/I/612hP4oGXLL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3ZjhnzB",
		"publishedDate": "2017-04-10"
	},
	{
		"title": "知りたいレイアウトデザイン　Second Edition 知りたいデザイン",
		"author": "ARENSKI",
		"asin": "B0CGZBHVPL",
		"isbn": "",
		"tags": ['layout'],
		"image": "https://m.media-amazon.com/images/I/71Wrdy2Fw5L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3CTl3Ax",
		"publishedDate": "2023-09-25"
	},
	{
		"title": "グラフィックデザインにおける秩序と構築 レイアウトグリッドの読み方と使い方",
		"author": "ユリシーズ・フェルカー",
		"asin": "",
		"isbn": "4802511728",
		"tags": ['layout', 'grid'],
		"image": "https://m.media-amazon.com/images/I/71uuUlseIUL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3CW4kg0",
		"publishedDate": "2020-03-24"
	},
	{
		"title": "っぽくなるデザイン　誰でもできるかっこいいレイアウト集",
		"author": "ingectar-e",
		"asin": "B0855H8MP6",
		"isbn": "",
		"tags": ['layout'],
		"image": "https://m.media-amazon.com/images/I/813a3W4bWZL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3VjW93c",
		"publishedDate": "2020-03-03"
	},
	{
		"title": "7日間でマスターする レイアウト基礎講座 (デザイン ビギナー シリーズ)",
		"author": "内田 広由紀",
		"asin": "B07952TH5Y",
		"isbn": "4881081438",
		"tags": ['layout'],
		"image": "https://m.media-amazon.com/images/I/9100GFdw4JS._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3OBQVMs",
		"publishedDate": "1998-10-01"
	},
	{
		"title": "レイアウトデザイン見本帳 レイアウトの意味と効果が学べるガイドブック",
		"author": "関口 裕, 内藤 タカヒコ, 長井 美樹, 佐々木 剛士, 鈴木 貴子, 市川 水緒",
		"asin": "",
		"isbn": "4844366629",
		"tags": ['layout'],
		"image": "https://m.media-amazon.com/images/I/71wzDO54i1L._SL1000_.jpg",
		"amazonUrl": "https://amzn.to/3ZjdrPc",
		"publishedDate": "2017-04-18"
	},
	{
		"title": "レイアウトのデザインパターン帖",
		"author": "パイ インターナショナル",
		"asin": "B07H263WWT",
		"isbn": "",
		"tags": ['layout'],
		"image": "https://m.media-amazon.com/images/I/81k22mcpFJL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3Z7NXnW",
		"publishedDate": "2011-07-18"
	},
	{
		"title": "やさしいレイアウトの教科書［改訂版］",
		"author": "大里 浩二, 内藤 孝彦, 長井 美樹, 山崎 澄子",
		"asin": "B07H7D4YJG",
		"isbn": "",
		"tags": ['layout'],
		"image": "https://m.media-amazon.com/images/I/81LYCO7nFTL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4gbotNm",
		"publishedDate": "2018-09-19"
	},
	{
		"title": "デザイナーズハンドブック　レイアウト編",
		"author": "佐々木剛士, 風日舎, 田村浩",
		"asin": "",
		"isbn": "4756246621",
		"tags": ['layout'],
		"image": "https://m.media-amazon.com/images/I/71ezUD1eC7L._SL1102_.jpg",
		"amazonUrl": "https://amzn.to/3ZARCw8",
		"publishedDate": "2015-07-13"
	},
	{
		"title": "一目で伝わる 構図とレイアウト",
		"author": "",
		"asin": "",
		"isbn": "4756244769",
		"tags": ['layout'],
		"image": "https://m.media-amazon.com/images/I/51zKH7gZhvL._SL1000_.jpg",
		"amazonUrl": "https://amzn.to/3Zub914",
		"publishedDate": "2014-04-20"
	},
	{
		"title": "コンセプトをカタチにする 世界のブランディングデザイン",
		"author": "gestalten",
		"asin": "",
		"isbn": "4766135768",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/71YIVsFqTxL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3OZewXX",
		"publishedDate": "2021-12-08"
	},
	{
		"title": "ブランドのはじめかた",
		"author": "中川淳, 西澤明洋",
		"asin": "B0756S575R",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/81-hqWq75IL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3ZCK6AP",
		"publishedDate": "2010-10-28"
	},
	{
		"title": "ブランドのそだてかた",
		"author": "中川淳, 西澤明洋",
		"asin": "B0756W385Y",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/61MbXKN+n9L._SL1110_.jpg",
		"amazonUrl": "https://amzn.to/49Crnc5",
		"publishedDate": "2012-10-18"
	},
	{
		"title": "ブランドらしさのつくり方",
		"author": "博報堂ブランドデザイン",
		"asin": "B01H145296",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/71LvMWkl7VL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3OFgzA8",
		"publishedDate": "2006-09-28"
	},
	{
		"title": "デザインを、経営のそばに。",
		"author": "八木 彩",
		"asin": "B0CQZN96XN",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/71CGbYN7WXL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/41hNXnR",
		"publishedDate": "2024-01-10"
	},
	{
		"title": "DJ_LOCAL 2017年1月号「地域ブランドデザイン集」 [雑誌] 別冊 Discover Japan",
		"author": "ディスカバー・ジャパン編集部",
		"asin": "B01N3C0PK1",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/81j5QZBdR2L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/41hO0Qz",
		"publishedDate": "2019-07-01"
	},
	{
		"title": "ブランドのデザイン",
		"author": "川島 蓉子",
		"asin": "4335450338",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/41PDSYHD18L.jpg",
		"amazonUrl": "https://amzn.to/3VnW3HZ",
		"publishedDate": "2006-08-03"
	},
	{
		"title": "生まれ変わるデザイン、持続と継続のためのブランド戦略ー老舗のデザイン・リニューアル事例から学ぶ、ビジネスのためのブランド・デザインマネジメント",
		"author": "ウジ トモコ",
		"asin": "B01HPH6KEU",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/61sR2bkGBGL._SL1241_.jpg",
		"amazonUrl": "https://amzn.to/4gitnbc",
		"publishedDate": "2016-06-30"
	},
	{
		"title": "ブランドをデザインする!",
		"author": "西澤 明洋",
		"asin": "4756240658",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/81s+0hgLo2S._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4gk9bWu",
		"publishedDate": "2011-01-18"
	},
	{
		"title": "事例で学ぶブランディング ランドーのデザイン戦略大公開",
		"author": "ランドーアソシエイツ",
		"asin": "4802511442",
		"isbn": "",
		"tags": ['branding'],
		"image": "https://m.media-amazon.com/images/I/71PrD0p2fjL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4fVCJKx",
		"publishedDate": "2020-04-20"
	},
	{
		"title": "要点で学ぶ、ロゴの法則150",
		"author": "生田 信一, 板谷 成雄, 大森 裕二, 酒井 さより, シオザワヒロユキ, 宮後 優子",
		"asin": "B09N3QM51F",
		"isbn": "",
		"tags": ['logo'],
		"image": "https://m.media-amazon.com/images/I/713HJzWx5sL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4gjM6mT",
		"publishedDate": "2021-12-15"
	},
	{
		"title": "アニメ・ゲームのロゴデザイン",
		"author": "BNN編集部",
		"asin": "B0BVLMKWYX",
		"isbn": "",
		"tags": ['logo'],
		"image": "https://m.media-amazon.com/images/I/71PQb90+HOL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/49pjtlT",
		"publishedDate": "2023-02-20"
	},
	{
		"title": "ロゴデザインの教科書　良質な見本から学べるすぐに使えるアイデア帳",
		"author": "植田 阿希",
		"asin": "B08CB21HXL",
		"isbn": "",
		"tags": ['logo'],
		"image": "https://m.media-amazon.com/images/I/817wRNgYFHL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4ioh1R4",
		"publishedDate": "2020-07-21"
	},
	{
		"title": "ロゴのつくりかたアイデア帖\"いい感じ\"に仕上げる65の引き出し",
		"author": "遠島 啓介",
		"asin": "B085RVN7VG",
		"isbn": "",
		"tags": ['logo'],
		"image": "https://m.media-amazon.com/images/I/71N-XAgiMeL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4iiLlwh",
		"publishedDate": "2020-03-16"
	},
	{
		"title": "ロゴのかたち　クリエイティブな70の発想法",
		"author": "松永克輝",
		"asin": "B0DH7PPKXJ",
		"isbn": "",
		"tags": ['logo'],
		"image": "https://m.media-amazon.com/images/I/81jV2Fn20uL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/4ij3V7r",
		"publishedDate": "2024-09-20"
	},
	{
		"title": "あたらしいロゴとツール展開　ブランドの世界観を伝えるデザイン",
		"author": "BNN編集部",
		"asin": "B0953KXG8B",
		"isbn": "",
		"tags": ['logo'],
		"image": "https://m.media-amazon.com/images/I/719DYLrifXS._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/49onDdZ",
		"publishedDate": "2020-12-22"
	},
	{
		"title": "江戸・明治のロゴ図鑑: 登録商標で振り返る企業のマーク",
		"author": "友利 昴",
		"asin": "",
		"isbn": "4867930474",
		"tags": ['logo'],
		"image": "https://m.media-amazon.com/images/I/71K1rFtZDEL._SL1450_.jpg",
		"amazonUrl": "https://amzn.to/3Bmt4gM",
		"publishedDate": "2024-09-26"
	},
	{
		"title": "ロゴデザインの原則　効果的なロゴタイプ、シンボル、アイコンを作るためのテクニック",
		"author": "ジョージ・ボクア",
		"asin": "B0CCHKVM9G",
		"isbn": "",
		"tags": ['logo'],
		"image": "https://m.media-amazon.com/images/I/71+M+FwT46L._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3VpWa5U",
		"publishedDate": "2023-07-27"
	},
	{
		"title": "たのしいロゴづくり -文字の形からの着想と展開",
		"author": "甲谷 一",
		"asin": "",
		"isbn": "4861008395",
		"tags": ['logo'],
		"image": "https://m.media-amazon.com/images/I/518CcOY5r3L.jpg",
		"amazonUrl": "https://amzn.to/3ZoYZ8e",
		"publishedDate": "2012-09-18"
	},
	{
		"title": "ロゴデザインのコツ　プロのクオリティに高めるための手法65",
		"author": "佐藤 浩二",
		"asin": "B0BZVPMC5Z",
		"isbn": "",
		"tags": ['logo'],
		"image": "https://m.media-amazon.com/images/I/71BWZr6onyL._SL1500_.jpg",
		"amazonUrl": "https://amzn.to/3ZjLOpq",
		"publishedDate": "2023-04-19"
	}
];