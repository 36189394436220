import React from 'react';
import { useIntl } from 'react-intl';

const Navigation = () => {
	const intl = useIntl();

	return (
		<nav>
			<ul className="flex space-x-4 text-sm">
				<li>
					<a className="hover:underline" href="/about">
						{intl.formatMessage({ id: 'nav.about' })}
					</a>
				</li>
			</ul>
		</nav>
	);
};

export default Navigation;