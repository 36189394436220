import React, { useState, useEffect } from 'react';
import { books } from '../../data';
import SortDropdown from './SortDropdown';
import BookGrid from './BookGrid';
import SearchBar from './SearchBar';
import ViewToggle from './ViewToggle';
import BookList from './BookList';
import { useIntl } from 'react-intl';
import NotFoundBook from './NotFoundBook';
import TagFilter from './TagFilter';
// import { getBookInfo } from '../../amazon-api';

const Main = () => {
	const intl = useIntl();
	const [isGridView, setIsGridView] = useState(true);
	const [sortedBooks, setSortedBooks] = useState([]);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [sortOption, setSortOption] = useState(
		intl.formatMessage({ id: 'sort.newest' })
	);
	const [searchTerm, setSearchTerm] = useState('');
	const [selectedTags, setSelectedTags] = useState([]);

	useEffect(() => {
		// const testAmazonApi = async () => {
		// 	try {
		// 		const result = await getBookInfo('B01M721DIP');
		// 		console.log('書籍情報:', result);
		// 	} catch (error) {
		// 		console.error('APIエラー:', error);
		// 	}
		// };

		// testAmazonApi();

		const initialSortedBooks = [...books].sort((a, b) => {
			return new Date(b.publishedDate) - new Date(a.publishedDate);
		});
		setSortedBooks(initialSortedBooks);
	}, []);

	const filterBooks = (books, searchTerm, tags) => {
		return books.filter(book => {
			const matchesSearch = book.title.toLowerCase().includes(searchTerm.toLowerCase());
			const matchesTags = tags.length === 0 || (tags.length === 1 && book.tags && book.tags.includes(tags[0]));
			return matchesSearch && matchesTags;
		});
	};

	const sortByDate = (books, order) => {
		return [...books].sort((a, b) => {
			return order === '新しい'
				? new Date(b.publishedDate) - new Date(a.publishedDate)
				: new Date(a.publishedDate) - new Date(b.publishedDate);
		});
	};

	const handleSortOptionChange = (option) => {
		setSortOption(option);
		setIsDropdownOpen(false);
		const filtered = filterBooks(books, searchTerm, selectedTags);
		const sorted = sortByDate(filtered, option === intl.formatMessage({ id: 'sort.newest' }) ? '新しい' : '古い');
		setSortedBooks(sorted);
	};

	const handleSearchChange = (event) => {
		const value = event.target.value;
		setSearchTerm(value);

		// フィルタリングを実行
		const filtered = filterBooks(books, value, selectedTags);

		// 現在の並び替えオプションに基づいてソート
		const sorted = sortByDate(filtered, sortOption === intl.formatMessage({ id: 'sort.newest' }) ? '新しい' : '古い');
		setSortedBooks(sorted);
	};

	const handleTagSelect = (tag) => {
		const newTags = selectedTags[0] === tag ? [] : [tag];
		setSelectedTags(newTags);

		// フィルタリングを実行
		const filtered = filterBooks(books, searchTerm, newTags);

		// 現在の並び替えオプションに基づいてソート
		const sorted = sortByDate(filtered, sortOption === intl.formatMessage({ id: 'sort.newest' }) ? '新しい' : '古い');
		setSortedBooks(sorted);
	};

	const clearFilters = () => {
		setSearchTerm('');
		setSelectedTags([]);
		setSortedBooks([...books].sort((a, b) => {
			return new Date(b.publishedDate) - new Date(a.publishedDate);
		}));
	};

	const availableTags = [...new Set(books.flatMap(book => book.tags || []))];

	return (
		<main className="flex-grow p-4 relative">
			<div className="flex flex-col md:flex-row justify-between mb-4">
				<SearchBar searchTerm={searchTerm} handleSearchChange={handleSearchChange} />
				<div className="flex justify-end items-center gap-4">
					<ViewToggle isGridView={isGridView} setIsGridView={setIsGridView} />
					<SortDropdown
						isDropdownOpen={isDropdownOpen}
						toggleDropdown={() => setIsDropdownOpen(!isDropdownOpen)}
						sortOption={sortOption}
						handleSortOptionChange={handleSortOptionChange}
					/>
				</div>
			</div>

			<TagFilter
				availableTags={availableTags}
				selectedTags={selectedTags}
				handleTagSelect={handleTagSelect}
			/>

			<section id="book-list">
				{sortedBooks.length > 0 ? (
					isGridView ? (
						<BookGrid books={sortedBooks} />
					) : (
						<BookList books={sortedBooks} />
					)
				) : (
					<NotFoundBook clearFilters={clearFilters} />
				)}
			</section>
		</main>
	);
};

export default Main;
