import React from 'react';
import Logo from './Logo';
import Navigation from './Navigation';
import LanguageSwitcher from './LanguageSwitcher';

const Header = ({ onLanguageChange, currentLocale }) => {
	return (
		<header className="flex justify-between items-center py-4 px-6 bg-white shadow-md">
			<div>
				<Logo />
			</div>
			<div className="flex items-center gap-4">
				<Navigation />
				<LanguageSwitcher onLanguageChange={onLanguageChange} currentLocale={currentLocale} />
			</div>
		</header>
	);
};

export default Header;
