const messages = {
	'books.count': '冊のデザイン本を紹介中！',
	'sort.newest': '出版日が新しい順',
	'sort.oldest': '出版日が古い順',
	'view.list': 'リスト表示',
	'view.grid': 'グリッド表示',
	'search.placeholder': '本のタイトルを検索',
	'search.noResults': '該当する本はありませんでした。',
	'search.tryOther': '他の検索条件をお試しください。',
	'button.clearFilters': '検索条件をクリア',
	'nav.about': 'このサイトについて',
	'tag.design': 'デザイン',
	'tag.branding': 'ブランディング',
	'tag.interaction': 'インタラクション',
	'tag.review': 'レビュー',
	'tag.typography': 'タイポグラフィ',
	'tag.social': '社会',
	'tag.graphic': 'グラフィック',
	'tag.grid': 'グリッド',
	'tag.ux': 'UX',
	'tag.ui': 'UI',
	'tag.research': 'リサーチ',
	'tag.method': '手法',
	'tag.theory': '理論',
	'tag.designsystem': 'デザインシステム',
	'tag.facilitation': 'ファシリテーション',
	'tag.workshop': 'ワークショップ',
	'tag.web': 'Web',
	'tag.dtp': 'DTP',
	'tag.layout': 'レイアウト',
	'tag.psychology': '心理学',
	'tag.service': 'サービスデザイン',
	'tag.infographic': 'インフォグラフィック',
	'tag.storytelling': 'ストーリーテリング',
	'tag.advertising': '広告',
	'tag.color': '色',
	'tag.ia': '情報アーキテクチャ',
	'tag.font': 'フォント',
	'tag.economy': '経済',
	'tag.logo': 'ロゴ',
	'tag.figma': 'Figma',
	'tag.packaging': 'パッケージ',
	'tag.accessibility': 'アクセシビリティ',
	'tag.prototyping': 'プロトタイピング',
};

export default messages; 